import React, { useState, useEffect } from "react";
import MainLayout from "src/components/layoutmain";
import { StaticImage } from "gatsby-plugin-image";
import LayoutCentered from "../components/layoutcentered";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


/**
 * Page contact du site numeko
 * @returns {JSX.Element}
 * @constructor
 */
export default function Contact() {
  const seoProps = {
    title: "Numeko - Contact",
    description:
      "Un projet ? Une collaboration ? Intéressé pour collaborer. Curieux. Le numérique responsable et l'écoconception te parle.",
  };


  const [value, setValue] = React.useState({})
  const [serverResponse, setServerResponse] = React.useState(``)

  const [loading, setLoading] = React.useState(false)

  const [stateForm, setStateForm] = useState({
    description : '',
    budget: 0,
    fichiers: [],
    nom: '',
    prenom: '',
    societe: '',
    mail: '',
    tel: '',
    tag: ''
  })


  useEffect(() => {
    if(window.location.href.includes("preprod")){
      setStateForm({...stateForm, tag: "preprod"})
    }else{
      setStateForm({...stateForm, tag: "prod"})
    }
  }, [])


  const handleSubmit = async (event) => {
    setLoading(true)
      event.preventDefault()
      const response = await fetch("/api/createTask", {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
         body: JSON.stringify(stateForm)
      })

    const taskId = await response.json()

    setLoading(false)

    if(response.status !== 200){
      showToast('error')
    }else{
      showToast()
      setStateForm({
        description : '',
        budget: 0,
        fichiers: [],
        nom: '',
        prenom: '',
        societe: '',
        mail: '',
        tel: '',
        tag: ''
      })
    }

    //TODO : Solution pour l'upload des fichiers ( upload sur le serveur et ensuite données le path à l'API ( createTaskAttachement.php )
     /* await fetch("/api/createTaskAttachment", {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        method: 'POST',
        body: JSON.stringify({
          'fichiers' : stateForm.fichiers,
          'taskId' : taskId
        })
      }).then(r =>r.json())*/




  }


  const notify = () => toast("Wow so easy !");


  const showToast = (status = "") => {
    if(status === "error"){
      toast.error("Erreur lors de l'envoi du formulaire",
         {
           position: "bottom-center",
           autoClose: 5000,
           hideProgressBar: true,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "colored",
         });
    }else{
      toast.success("Projet envoyé",
         {
           position: "bottom-center",
           autoClose: 5000,
           hideProgressBar: true,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
         });
    }
  }
  return (
    <MainLayout seoProps={seoProps}>
      <div className={"contact"}>
        <div className={"bloc-category bloc-presentation"}>
          <LayoutCentered className={"max-height"} size={"full"}>
            <h1>Un projet ? Une collaboration ?</h1>
            <h3>Tout simplement, <span>une question</span> ?</h3>
          </LayoutCentered>
        </div>
        <div className={"bloc-category bloc-content background-waves-one-color-duck"}>
          <LayoutCentered className={"max-height"} size={"full"}>
          <div className={"container"}>
            <ToastContainer />
            <form onSubmit={handleSubmit} method="post">

              <div className={"form-column"}>
                <div className={"group textarea"}>
                  <label>Décrivez votre projet :</label>
                  <textarea rows="5" cols="50" name="description" onChange={e => setStateForm({...stateForm, description: e.target.value})} value={stateForm.description} required/>
                </div>
              </div>

              <div className={"form-column"}>
                <div className={"group slide"}>
                  <label>Votre budget prévisionnel :</label>
                  <input type="range" min="0" max="100000" step="100" value={stateForm.budget} onChange={e => setStateForm({...stateForm, budget: e.target.value})}  name="budget" />
                  <span className={"value-range"}>{stateForm.budget} €</span>
                </div>
              </div>

             {/* <div className={"form-column"}>
                <div className={"group file"}>
                  <label>Des fichiers à nous transmettre:</label>
                  <input type="file" name="fichiers" onChange={e => setStateForm({...stateForm, fichiers: e.target.value})}/>
                </div>
              </div>*/}


              <div className={"form-column"}>
                <div className={"form-row"}>
                  <div className={"group text"}>
                    <label>Nom</label>
                    <input type="text" name="nom" onChange={e => setStateForm({...stateForm, nom: e.target.value})} value={stateForm.nom} required/>
                  </div>
                  <div className={"group text"}>
                    <label>Prénom</label>
                    <input type="text" name="prenom" onChange={e => setStateForm({...stateForm, prenom: e.target.value})} value={stateForm.prenom} required/>
                  </div>
                </div>
                <div className={"form-row"}>
                  <div className={"group text"}>
                    <label>Société</label>
                    <input type="text" name="societe" onChange={e => setStateForm({...stateForm, societe: e.target.value})} value={stateForm.societe} required/>
                  </div>
                  <div className={"group text"}>
                    <label>Téléphone</label>
                    <input type="text" name="tel" onChange={e => setStateForm({...stateForm, tel: e.target.value})} value={stateForm.tel} required/>
                  </div>
                </div>
                <div className={"form-row"}>
                  <div className={"group text"}>
                    <label>Email</label>
                    <input type="email" name="mail" onChange={e => setStateForm({...stateForm, mail: e.target.value})} value={stateForm.mail} required/>
                </div>
                </div>
              </div>



              <div className={"form-center"}>
                 <input className={loading ? "button-more-disabled" : "button-more"} type="submit" value="Envoyer"  disabled={loading}/>
              </div>

            </form>

          </div>

        </LayoutCentered >

        </div>
      </div>
    </MainLayout>
  );
}
